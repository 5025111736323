<template>
    <KCrudLayout :filters.sync="filters">
      <template #header>
        {{ $tc('client.title', 2) }}
      </template>
      <template #filters="{attrs, on}">
        <ClientFilter v-bind="attrs" v-on="on" />
      </template>
      <template #view.list>
          <Resource
            class="client-resource"
            :create-request="createHandler"
            :delete-request="deleteHandler"
            :parameters="filters"
            :form-component="() => import('../components/forms/ClientForm.vue')"
            :index-request="indexHandler"
            :meta="{name: $tc('client.title', 1), namePlural: $tc('client.title', 2)}"
            :model-type="modelType"
            :show-request="showHandler"
            :table-content="tableContent"
            :update-request="updateHandler"
            :can-add="can('client.all')"
            :can-delete="can('client.all')"
            :can-update="can('client.all')"
            @row-click="openClient"
          />
      </template>
    </KCrudLayout>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { create, index, remove, show, update } from '../api/endpoints/client.js';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import ClientFilter from '@/components/client/ClientFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import Client from '@/application/models/client.js';
import { mapGetters } from "vuex";

export default {
  name: 'ClientResource',
  mixins: [querySyncMixin],

  components: {
    KCrudLayout,
    Resource,
    ClientFilter,
  },
  data: () => ({
    filters: { isActive: 1 },
  }),
  computed: {
    ...mapGetters("profile", ["can"]),
    indexHandler: () => index,
    showHandler: () => show,
    updateHandler: () => update,
    deleteHandler: () => remove,
    createHandler: () => create,
    modelType: () => Client,
    tableContent() {
      return [
        {
          text: this.$t('client.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('client.fields.isActive'),
          align: 'left',
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
    ]);
  },
  methods: {
    openClient(clientId) {
      if (this.can("client.connector.read")) {
        this.$router.push({
          name: 'client.connectors',
          params: { clientId },
        });
        return;
      }
      if (this.can("client.product.read")) {
        this.$router.push({
          name: 'client.products',
          params: { clientId },
        });
        return;
      }
    },
  },
};
</script>
